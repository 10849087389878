<template>
	<div class="article-box">
		<el-col class="col" :span="8">

		
				<p>
				<span @click="$router.go(-1)" style="color: #409EFF;cursor: pointer;">设备批次管理</span> /
				<span>批次管理</span>
				
				</p>
				
		
		</el-col>
		<el-table :data="machineData" style="width: 100%" v-loading="loading">
			<!-- <el-table-column prop="serial" label="序号" type="index" align="center"></el-table-column>   -->
			<el-table-column type="index" label="序号" align="center"></el-table-column>
			<el-table-column prop="machine_number" label="设备编号" align="center">

			</el-table-column>
			<el-table-column prop="id" label="设备ID" align="center" width="150"></el-table-column>
			<el-table-column prop="register_user" label="注册人" align="center"></el-table-column>
			<el-table-column prop="register_date" label="注册时间" align="center" width="150"></el-table-column>
			<el-table-column label="操作" align="center" width="150">

				<template slot-scope="scope">
					<el-button class="el_buu" @click="dialogFormVisible = true" type="text" size="small">更换批次
					</el-button>

				</template>
			</el-table-column>
		</el-table>

		<el-dialog title="更换批次" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
			<el-form :model="machineEditBatch">
				<el-form-item label="此设备的注册批次为:" style="display: flex;justify-content: center;">
					<el-select placeholder="" v-model="machineEditBatch.id" style="width: 150px; " :min="0" :max="100"
						:controls="false">
						<el-option v-for="(item,index) in machinesData" :key="index" :label="item.batch"
							:value="item.id">
						</el-option>

					</el-select>
					<!-- <el-select-number v-model="num" :precision="1" :step="0.1" :max="42"></el-select-number> -->


				</el-form-item>

				<el-form-item style="margin-top:5px">
					<el-button @click="dialogFormVisible = false">取消</el-button>
					<el-button type="primary" @click="Adds">添加</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		machines,
		machineBatchList,
		machineEditBatch

	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				dialogFormVisible: false,
				batch_id: this.$route.params.batch_id,
				machine_id: this.$route.params.machine_id,
				machineData: [], // 设备列表
				machinesData: [],
				loading: false, //加载框
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数
				machineEditBatch: {
					id: null
				}, //接帅选项的数据options

			}

		},
		mounted() {
			// 获取年级列表
			this.machines()
			this.machineSerchList(1) // 获取第一页设备列表
		},
		methods: {
			machineSerchList(currpage) { // 设备列表
				this.loading = true
				const data = {
					batch_id: this.batch_id,
					page: currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				machineBatchList(data).then(res => {
					// console.log(res.data.list)
					this.machineData = res.data.list // 设备列表
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			machines() { //批次列表
				machines().then(res => {
					this.machinesData = res.data.list // 批次列表
					console.log(res)
					console.log(this.machinesData)
				}).catch(err => {
					console.log(err)
				})

			},
			Adds() { // 点击添加
				if (!this.machineEditBatch.id ) {
					this.$message.warning("批次不能为空")
					return
				}else{
					const data = {
					// batch: this.machineEditBatch.agent,
					batch_id: this.batch_id,
					machine_id: this.machine_id

				}
				machineEditBatch(data).then(res => {
					if (res.status.code == 1) {
						this.$message({
							message: '添加成功',
							type: 'success'
						})
						this.dialogFormVisible = false //关闭添加弹窗
						this.machineSerchList(this.currpage) // 刷新
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
				}
				

			},
			handleCurrentChange(val) { // 分页
				this.machineSerchList(val)
				this.currpage = val
			},


		}
	}
</script>

<style lang="scss" scoped>
	.col {

		width: 100%;
		display: flex;
		justify-content: space-between;

		.inp {
			width: 33.333%;
		}

		.btn {
			width: 10.0625rem;
			height: 3.375rem;
			background-color: #003882;
			font-size: 1.25rem;
		}
	}
</style>
